import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-18508fa1"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "-mt-1 text-xs leading-none text-gray-400"
}
const _hoisted_2 = { class: "text-lg font-medium text-right text-gray-400" }
const _hoisted_3 = {
  key: 0,
  class: "validation-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_BaseCard = _resolveComponent("BaseCard")!

  return (_openBlock(), _createBlock(_component_BaseCard, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_item, { lines: "none" }, {
        default: _withCtx(() => [
          (_ctx.icon)
            ? (_openBlock(), _createBlock(_component_ion_icon, {
                key: 0,
                size: "large",
                icon: _ctx.icon,
                class: "mr-4"
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_label, {
            class: _normalizeClass(["capitalize", _ctx.optional ? 'py-0 leading-relaxed' : 'py-1'])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
              (_ctx.optional)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Optional"))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_ion_input, {
            class: "text-right",
            name: _ctx.id,
            value: _ctx.modelValue,
            type: "number",
            placeholder: "0",
            inputmode: "numeric",
            onIonFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onFocus', $event))),
            onIonInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
          }, null, 8, ["name", "value"]),
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.unit), 1)
        ]),
        _: 1
      }),
      (_ctx.errors && _ctx.errors.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.errors[0]), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}