
import { defineComponent } from 'vue';
import { IonIcon, IonInput, IonLabel, IonItem } from '@ionic/vue';
import BaseCard from '@/components/Card/BaseCard.vue';

export default defineComponent({
  name: 'InputMeasurement',
  components: { BaseCard, IonIcon, IonInput, IonLabel, IonItem },
  props: {
    modelValue: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    unit: {
      type: String,
      required: false
    },
    optional: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: () => []
    }
  }
});
