
import MeasurementChecklistItem from '@/components/Card/MeasurementChecklistItem.vue';
import { IonButton, IonCol, IonGrid, IonRow } from '@ionic/vue';

export default {
  name: 'MeasurementChecklist',
  components: {
    MeasurementChecklistItem,
    IonButton,
    IonCol,
    IonGrid,
    IonRow
  }
};
