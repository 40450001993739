
import { InputMeasurement, SelectOptionWithValue } from '@/components/Input';
import { IonButton, IonCol, IonGrid, IonRow, IonToast, IonLoading } from '@ionic/vue';
import BaseCard from '@/components/Card/BaseCard.vue';
import PageTitle from '@/components/layout/PageTitle.vue';
import { useRouter } from 'vue-router';
import { computed, defineComponent, onMounted, reactive, ref } from 'vue';
import { recordPatientEvent } from '@/helpers/aws.helper';
import axios from 'axios';
import {
  HealthRecordOrdinalService,
  HealthRecordScaleService,
  HealthRecordTerminologyService,
  MeasurementService
} from '@/services';
import throttle from 'lodash-es/throttle';
import { isDischargedCheck } from '@/helpers/discharge.helper';
import temperatureIcon from '@resources/icons/temperature.svg';
import heartRateIcon from '@resources/icons/heart-rate.svg';
import spO2Icon from '@resources/icons/sp02.svg';

export interface ValidationErrors {
  perField: {
    [key: string]: string[];
  };
  message: string;
}

export default defineComponent({
  name: 'AddMeasurements',
  components: {
    SelectOptionWithValue,
    InputMeasurement,
    IonGrid,
    IonRow,
    IonCol,
    IonToast,
    IonLoading,
    IonButton,
    BaseCard,
    PageTitle
  },
  setup() {
    onMounted(async () => {
      showMeasurements.value = true;
      isLoading.value = true;

      recordPatientEvent('started taking own measurements');

      try {
        const [
          methodOfOxygen,
          borgScales,
          breathlessness,
          sputumProduction,
          sputumColour,
          wheeze,
          cough,
          soreThroat,
          runnyNose
        ] = await Promise.all([
          healthRecordTerminology.fetchTypeOfAir(),
          healthRecordScale.getScalesBorg(),
          healthRecordOrdinal.getBreathlessness(),
          healthRecordOrdinal.getSputumProd(),
          healthRecordOrdinal.getSputumColor(),
          healthRecordOrdinal.getWheeze(),
          healthRecordOrdinal.getCough(),
          healthRecordOrdinal.getSoreThroat(),
          healthRecordOrdinal.getRunnyNose()
        ]);

        // Oxygen Types
        methodOfOxygenOptions.value = mapOptions(methodOfOxygen.data, false);

        // BORG Scale
        borgSelectOptions.value = mapOptions(borgScales.data);

        // Day of Discharge
        breathlessnessOptions.value = mapOptions(breathlessness.data);
        sputumProductionOptions.value = mapOptions(sputumProduction.data);
        sputumColorOptions.value = mapOptions(sputumColour.data);
        wheezeOptions.value = mapOptions(wheeze.data);
        coughOptions.value = mapOptions(cough.data);

        // Last 24 Hours
        soreThroatOptions.value = mapOptions(soreThroat.data);
        runnyNoseOptions.value = mapOptions(runnyNose.data);
      } catch (e) {
        if (axios.isAxiosError(e) && e.response?.data?.message) {
          errors.message = e.response.data.message;
          displayToast(errors.message, 'danger');
        } else {
          displayToast('An unknown error occurred.', 'warning');
          throw e;
        }
      }

      isLoading.value = false;
    });

    const router = useRouter();

    const intialFormData = {
      temperature: '',
      heartRate: '',
      spo2: '',
      methodOfOxygen: null,
      flowRate: '',
      borgScale: null,
      breathlessness: null,
      sputumProduction: null,
      sputumColour: null,
      wheeze: null,
      cough: null,
      soreThroat: null,
      runnyNose: null
    };

    const showMeasurements = ref<boolean>(false);
    const formData = reactive({ ...intialFormData });
    const isLoading = ref<boolean>(false);
    const isToastOpen = ref<boolean>(false);
    const toastMessage = ref<string>('');
    const toastColor = ref<string>('primary');
    const displayToast = (message: string, color = 'primary') => {
      toastMessage.value = message;
      toastColor.value = color;
      isToastOpen.value = true;
    };

    const errors = reactive<ValidationErrors>({
      perField: {},
      message: ''
    });

    const clearError = (key: string) => {
      delete errors.perField[key];
    };

    const clearErrors = () => {
      errors.message = '';
      errors.perField = {};
    };

    const measurementService = new MeasurementService();
    const healthRecordScale = new HealthRecordScaleService();
    const healthRecordOrdinal = new HealthRecordOrdinalService();
    const healthRecordTerminology = new HealthRecordTerminologyService();

    const borgSelectOptions = ref([]);
    const methodOfOxygenOptions = ref([]);
    const breathlessnessOptions = ref([]);
    const sputumProductionOptions = ref([]);
    const sputumColorOptions = ref([]);
    const wheezeOptions = ref([]);
    const coughOptions = ref([]);
    const soreThroatOptions = ref([]);
    const runnyNoseOptions = ref([]);
    const isSubmitting = ref(false);

    const mapOptions = (items: any, isSymbol = true) =>
      items.map((item: any) => ({
        label: isSymbol ? item.symbol.label : item.label,
        value: item
      }));

    const checkDischargeStatus = throttle(async () => {
      const isDischarged: boolean = await isDischargedCheck();
      if (isDischarged) {
        router.push('/discharged');
      }
    }, 30000);

    const isRoomAir = computed(
      () => !!formData.methodOfOxygen && (formData.methodOfOxygen as any).code !== '722742002'
    );

    const submit = async () => {
      isSubmitting.value = true;

      clearErrors();

      const payload = {
        pulse: {
          rate: formData.heartRate || null
        },
        pulse_oximetry: {
          spo2: formData.spo2 || null,
          method_of_oxygen_delivery: formData.methodOfOxygen || null,
          flow_rate: formData.flowRate || null
        },
        body_temperature: formData.temperature
          ? {
              temperature: {
                magnitude: formData.temperature || null,
                units: 'Cel'
              }
            }
          : null,
        borg_breathlessness_scale: {
          amount_of_breathlessness: formData.borgScale || null
        },
        uhb_modified_anthonisen_criteria: {
          breathlessness: formData.breathlessness || null,
          sputum_production: formData.sputumProduction || null,
          sputum_colour: formData.sputumColour || null,
          cough: formData.cough || null,
          wheeze: formData.wheeze || null,
          sore_throat: formData.soreThroat || null,
          congested_runny_nose: formData.runnyNose || null
        }
      };

      try {
        await measurementService.sendMeasurement(payload);
        recordPatientEvent('completed taking own measurements');
        await router.push({ name: 'Measurement Confirmation' });
      } catch (e) {
        if (axios.isAxiosError(e) && e.response?.status === 422) {
          errors.perField = e.response.data.errors;
          errors.message = e.response.data.message;
        } else {
          displayToast('An unknown error occurred.', 'warning');
          throw e;
        }
      } finally {
        isSubmitting.value = false;
      }
    };

    return {
      isLoading,
      submit,
      isSubmitting,
      isRoomAir,
      isToastOpen,
      toastMessage,
      toastColor,
      errors,
      clearError,
      clearErrors,
      formData,
      temperatureIcon,
      heartRateIcon,
      spO2Icon,
      borgSelectOptions,
      methodOfOxygenOptions,
      breathlessnessOptions,
      sputumProductionOptions,
      sputumColorOptions,
      wheezeOptions,
      coughOptions,
      soreThroatOptions,
      runnyNoseOptions,
      checkDischargeStatus,
      showMeasurements
    };
  }
});
